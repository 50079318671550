<main class="main">

@if(showNavBar()) {
  <mat-menu #menu="matMenu" >
    <a mat-menu-item [routerLink]="'/settings'" routerLinkActive="active">
      <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
      <span>Indstillinger</span>
    </a>
    <a mat-menu-item [routerLink]="'/change-log'" routerLinkActive="active">
      <mat-icon fontSet="material-symbols-outlined">question_exchange</mat-icon>
      <span>Seneste Ændringer</span>
    </a>
    <button mat-menu-item (click)="logout()">
      <mat-icon >logout</mat-icon><span>Log Ud</span>
    </button>
    
    <button mat-menu-item disabled="">
      <span>Version {{versionNr}}</span>
    </button>
  </mat-menu>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" 
    mat-align-tabs="start" animationDuration="0ms" >
    <button mat-fab [matMenuTriggerFor]="menu" color="none"
      style="box-shadow: unset; border-radius: 10px; height: 40px; width: 40px;margin: 4px"
    >
      <mat-icon>menu</mat-icon>
    </button>
    @for(link of links; track link) {
      <a mat-tab-link
        [routerLink]="link" 
        routerLinkActive="active" 
        [active]="getPath()===link"
        ariaCurrentWhenActive="page">
        <b>{{linkTitle(link)}}</b>
      </a>
    }
    @if(session.isLoggedIn() && currentScreenSize !== "XSmall") {
      <span class="user-display">
        <div class="username-display">
          <p>Logget ind som</p>
          <h3>
            {{employeeName}}
          </h3>
        </div>
        <button mat-menu-item (click)="logout()">
          <mat-icon >logout</mat-icon><span>Log Ud</span>
        </button>
      </span>
    }
  </nav>
  <mat-tab-nav-panel #tabPanel ></mat-tab-nav-panel>
  
} 
  
  <router-outlet></router-outlet>
</main>