import { Injectable } from '@angular/core';
import { CanMatch, Route, UrlSegment } from '@angular/router';
import { map, Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AquilaRouteMatcher implements CanMatch {

  constructor(private session: SessionService) { }

  canMatch(route: Route, segments: UrlSegment[]): boolean {
    // might wait for an API call
    const companyId = this.session.getToken()?.companyId || NaN;
    if(companyId === 47275 
      || companyId === 39466)
      return true;
    return false;
  }
}
