import { Injectable } from '@angular/core';
import { CanMatch, Route, UrlSegment } from '@angular/router';
import { map, Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root'
})
export class Version2Matcher {
  constructor(private session: SessionService) { }

  canMatch(route: Route, segments: UrlSegment[]): boolean {
    // might wait for an API call
    const companyId = this.session.getToken()?.companyId || NaN;
    if(!environment.production
      || companyId === 106114 //Tømrermester Rene Munck ApS
      || companyId === 39466  //Aquila A/S
      || companyId === 47275  //Z - Aquila Trivntek
      || companyId === 52571  //X - FLC
      || companyId === 28335  //NemFlex
      || companyId === 36683  //FLC
    )
      return true;
    // if(companyId === 36683) {
    //   return ((this.session.token?.username?.charCodeAt(0) || 1) + new Date().getDay()) % 2 === 0
    // }
    return false;
  }
}
