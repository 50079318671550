/**
 * @license FLConsult 
 * (C) - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import { ResolveFn, Routes } from '@angular/router';
import { canDeactivateGuard } from './pending-changes.guard';
import { LoginComponent } from './login/login.component';
import { sessionGuard } from './session-guard.guard';
import { Version2Matcher } from './routeMatchers/version2-matcher.service';
import { AquilaRouteMatcher } from './routeMatchers/aquila-route-matcher.service';


const projectIdTitle: ResolveFn<string> = (route, state) => 
    Promise.resolve('NemFlex Projekt ' + route.paramMap.get("projectId"));

export const routes: Routes = [ 
    { 
        title: 'NemFlex Login',
        path: 'login', 
        component: LoginComponent
    },

    {
        title: "Indstillinger",
        path: "settings",
        //canMatch: [Version2MatcherService],
        loadComponent: () => import("./client-settings/client-settings.component")
            .then(m => m.ClientSettingsComponent),
        canActivate: [sessionGuard],
        children: [
            {
                path: "timer",
                loadComponent: () => import("./client-settings/table-editor/time-table-editor/time-table-editor.component")
                .then(m => m.TimeTableEditorComponent),
                canMatch: [Version2Matcher],
                canActivate: [sessionGuard]
            },
            {
                path: "project",
                canMatch: [Version2Matcher],
                loadComponent: () => import("./client-settings/table-editor/project-table-editor/project-table-editor.component")
                .then(m => m.ProjectTableEditorComponent),
                // loadComponent: () => import("./client-settings/project-table-customizer/project-table-customizer.component")
                //     .then(m => m.ProjectTableCustomizerComponent),
                canActivate: [sessionGuard]
            },
            {
                path: "project",
                loadComponent: () => import("./client-settings/project-table-customizer/project-table-customizer.component")
                    .then(m => m.ProjectTableCustomizerComponent),
                canActivate: [sessionGuard]
            },
            {
                path: "project-lines",
                loadComponent: () => import("./client-settings/table-editor/project-line-table-editor/project-line-table-editor.component")
                .then(m => m.ProjectLineTableEditorComponent),
                canMatch: [Version2Matcher],
                canActivate: [sessionGuard]
            },
            {
                path: "production-order",
                canMatch: [Version2Matcher],
                loadComponent: () => import("./client-settings/table-editor/production-order-table-editor/production-order-table-editor.component")
                .then(m => m.ProductionOrderTableEditorComponent),
                // loadComponent: () => import("./client-settings/project-table-customizer/project-table-customizer.component")
                //     .then(m => m.ProjectTableCustomizerComponent),
                canActivate: [sessionGuard]
            },
            {
                path: "production-order-lines",
                canMatch: [Version2Matcher],
                loadComponent: () => import("./client-settings/table-editor/production-order-line-table-editor/production-order-line-table-editor.component")
                .then(m => m.ProductionOrderLineTableEditorComponent),
                // loadComponent: () => import("./client-settings/project-table-customizer/project-table-customizer.component")
                //     .then(m => m.ProjectTableCustomizerComponent),
                canActivate: [sessionGuard]
            },
            {
                path: "defaults",
                canMatch: [Version2Matcher],
                loadComponent: () => import("./client-settings/default-value-editor/default-value-editor.component")
                .then(m => m.DefaultValueEditorComponent),
                canActivate: [sessionGuard]
            },
        ]
    },
    { 
        path: 'settings/:other', 
        redirectTo: 'settings',
    },

    {
        title: "Change Log",
        path: "change-log",
        loadComponent: () => import("./change-log/change-log.component")
            .then(m => m.ChangeLogComponent),
        canActivate: [sessionGuard]
    },

    //region Timer
    {
        title: "NemFlex Timer",
        path: 'hour-overview',
        canMatch: [AquilaRouteMatcher],
        canActivate: [sessionGuard],
        loadComponent: () => import('./page/custom/aquila/aquila-time-page/aquila-time-page.component')
            .then(m => m.AquilaTimePageComponent),
    },

    {
        title: "NemFlex Timer",
        path: 'hour-overview',
        canMatch: [Version2Matcher],
        canActivate: [sessionGuard],
        loadComponent: () => import('./page/time-page/time-page.component')
            .then(m => m.TimePageComponent),
    },
    
    {
        title: "NemFlex Timer",
        path: 'hour-overview/add',
        loadComponent: () => import('./hour-overview/time-journal-projects/create-time-journal.component')
            .then(m => m.CreateTimeJournalComponent),
        canActivate: [sessionGuard]
    }, { 
        title: 'NemFlex Timer',
        path: 'hour-overview', 
        loadComponent: () => import('./hour-overview/hour-overview.component')
            .then(m => m.HourOverviewComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [sessionGuard]
    },
    
    //endregion
    //region Projekter
    {
        title: projectIdTitle, 
        path: 'project-overview/:projectId', //TODO https://angular.dev/guide/routing/common-router-tasks#setting-the-page-title
        canMatch: [Version2Matcher],
        loadComponent: () => import('./page/project-line-page/project-line-page.component')
            .then(m => m.ProjectLinePageComponent),
        canActivate: [sessionGuard]
    },{ 
        title: "NemFlex Projekter",
        path: 'project-overview',
        canMatch: [Version2Matcher],
        canActivate: [sessionGuard],
        loadComponent: () => import('./page/project-page/project-page.component')
            .then(m => m.ProjectPageComponent),
    },


    {
        title: projectIdTitle, 
        path: 'project-overview/:projectId/edit/:lineId', 
        loadComponent: () => import('./project-overview/project/edit-project-line/edit-project-line.component')
                .then(m => m.EditProjectLineComponent), 
        canDeactivate: [canDeactivateGuard],
        canActivate: [sessionGuard]
    },{
        title: projectIdTitle, 
        path: 'project-overview/:projectId/attach/pdf/:projectId/:docsRowId', 
        loadComponent: () => import('./pdf-viewer/pdf-viewer.component')
                .then(m => m.PdfViewerComponent), 
        canDeactivate: [canDeactivateGuard],
        canActivate: [sessionGuard]
    },{
        title: projectIdTitle, 
        path: 'project-overview/:projectId/attach', 
        loadComponent: () => import('./project-overview/project/attachment-uploader/attachment-uploader.component')
                .then(m => m.AttachmentUploaderComponent), 
        canDeactivate: [canDeactivateGuard],
        canActivate: [sessionGuard]
    },{
        title: projectIdTitle, 
        path: 'project-overview/:projectId', //TODO https://angular.dev/guide/routing/common-router-tasks#setting-the-page-title
        loadComponent: () => import('./project-overview/project/project.component')
            .then(m => m.ProjectComponent),
        canActivate: [sessionGuard]
    },{ 
        title: 'NemFlex Projekter',
        path: 'project-overview', 
        loadComponent: () => import('./project-overview/project-overview.component')
            .then(m => m.ProjectOverviewComponent),
        canActivate: [sessionGuard]
    },
    //endregion

    //region Produktionsordre
    {
        title: "NemFlex Produktionsordre",
        path: 'production-order',
        canMatch: [Version2Matcher],
        canActivate: [sessionGuard],
        loadComponent: () => import('./page/production-order-page/production-order-page.component')
            .then(m => m.ProductionOrderPageComponent),
    },{
        title: "NemFlex Produktionsordre",
        path: 'production-order/:prodOrdNr',
        canMatch: [Version2Matcher],
        canActivate: [sessionGuard],
        loadComponent: () => import('./page/productionr-order-line-page/productionr-order-line-page.component')
            .then(m => m.ProductionrOrderLinePageComponent),
    },

    {
        title: 'NemFlex Produktionsordre',
        path: 'production-order',
        loadComponent: () => import('./production-order/production-order.component')
            .then(m => m.ProductionOrderComponent),
        canActivate: [sessionGuard]
    },{
        title: 'NemFlex Produktionsordre',
        path: 'production-order/:prodOrdNr',
        loadComponent: () => import('./production-order/production-order-lines/production-order-lines.component')
            .then(m => m.ProductionOrderLinesComponent),
        canActivate: [sessionGuard]
    },

    {
        title: 'NemFlex Overblik',
        path: 'overview',
        loadComponent: () => import('./activity-overview/activity-overview.component')
            .then(m => m.ActivityOverviewComponent),
        canActivate: [sessionGuard]
    },

    
    { 
        title: 'NemFlex Login',
        path: '', 
        component: LoginComponent
    },
    // { 
    //     path: '**', 
    //     redirectTo: '/.', 
    //     pathMatch: 'full'
    // },
]