/**
 * @license FLConsult 
 * (C) - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker } from '@angular/service-worker';
import { routes } from './app.routes';


import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { LOCALE_ID } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
registerLocaleData(localeDa);

export const appConfig: ApplicationConfig = {
  providers: [
    //RESTfull comunication with WebAPI
    //importProvidersFrom(HttpClientModule), ### Deprecated ###
    provideHttpClient(withInterceptorsFromDi()),

    //Number and Date format #### Curently global, TODO internationalize
    { provide: LOCALE_ID, useValue: 'da'},

    //Routing - prevent app closing on native navigate back
    provideRouter(routes, withComponentInputBinding(), ),

    //Angular Materials
    //provideClientHydration(),
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }, 
    provideAnimationsAsync(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'fixed' } },
    
    provideNativeDateAdapter(),

    //Barcode Scanner
    // provideServiceWorker('ngsw-worker.js', {
    //     enabled: !isDevMode(),
    //     registrationStrategy: 'registerWhenStable:30000'
    // }),
    // provideServiceWorker('ngsw-worker.js', {
    //     enabled: !isDevMode(),
    //     registrationStrategy: 'registerWhenStable:30000'
    // }),
    
]
};
